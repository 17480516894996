import React, { useState } from 'react';
import Swal from 'sweetalert2';

const API_URL = process.env.REACT_APP_API_URL;

const ChangePassword = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Realizar la solicitud al backend
    try {
      const response = await fetch(`${API_URL}/api/change-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, newPassword }),
      });

      const result = await response.json();
      if (response.ok) {
        Swal.fire('Éxito', 'La contraseña ha sido actualizada', 'success');
        setEmail('');
        setNewPassword('');
      } else {
        Swal.fire('Error', result, 'error');
      }
    } catch (error) {
      console.error('Error al cambiar la contraseña:', error);
      Swal.fire('Error', 'Hubo un problema al cambiar la contraseña', 'error');
    }
  };

  return (
    <div>
      <h2>Cambiar Contraseña</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Correo Electrónico:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Nueva Contraseña:
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </label>
        </div>
        <button type="submit">Actualizar</button>
      </form>
    </div>
  );
};

export default ChangePassword;
